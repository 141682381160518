<template>
    <div>
        <div class="bg-sp-dark bottom-radius">
            <div class="container">
                <div class="row py-5">
                    <div class="text-center text-md-start col-md-6 py-4">
                        <h1>Welkom<span v-html='familieNaam'></span></h1>
                        <h5 class="col-12 col-md-8">Op deze pagina kun je jezelf of gezinsleden aanmelden voor een cursus bij Skipiste Nieuwegein.</h5>
                    </div>
                    <div v-if='aanmeldingStarten' class="col-md-5 offset-md-1 d-flex align-items-center justify-content-center">
                        <router-link class="float-start btn btn-primary btn-aanmelding w-100"  :to="{ name: 'StapPersoon' }"><div>Nieuwe aanmelding starten <font-awesome-icon class="ms-2" icon="fa-solid fa-arrow-right" /></div></router-link>
                    </div>
                    <div v-else class="col-md-5 offset-md-1">
                        <h4 class="text-center text-md-start">Je bent bezig met een aanmelding</h4>
                        <div class="d-inline-flex w-100">
                          <button
                          @click="$store.dispatch('aanmelding/reset')"
                          class="btn float-start" id="verwijder-aanmelding">
                            <font-awesome-icon icon="fa-solid fa-trash" />
                          </button>
                          <router-link class="float-start btn btn-primary btn-aanmelding"  :to="{ name: 'StapPersoon' }">
                            Rond je aanmelding af <font-awesome-icon class="ms-2" icon="fa-solid fa-arrow-right" />
                          </router-link>
                        </div>

                        <!-- <div class="aanmelding-card">
                            <h4 class='mb-0 mt-1'><strong>{{this.$store.state.aanmelding.cursus.titel}}</strong></h4>
                            <h6>{{this.$store.state.aanmelding.client.firstname}} &middot; {{this.$store.state.aanmelding.activiteit.titel}}</h6>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="bg-sp-light overlay-radius">
            <div id='info-container' class="container pt-5">
                <div class="row">
                    <div class="col-md-8">
                      <div class="row">
                        <h4>Jouw aanmeldingen</h4>
                      </div>
                        <div class="row">
                            <ul id='option-holder' class="d-flex gap-2 mb-3">
                                <li @click="setActiveStatusGroup(0)" :class="activeStatusGroup === 0 ? 'active-option' : ''">
                                    <p class="options">In behandeling</p>
                                </li>
                                <li @click="setActiveStatusGroup(1)" :class="activeStatusGroup === 1 ? 'active-option' : ''">
                                    <p class="options">Ingepland</p>
                                </li>
                                <li @click="setActiveStatusGroup(2)" :class="activeStatusGroup === 2 ? 'active-option' : ''">
                                    <p class="options">Geannuleerd</p>
                                </li>
                            </ul>
                        </div>
                        <div class="row">
                            <div class="col-12 d-grid mb-0 mb-md-5" id="aanmeldingen-grid">
                                <aanmeld-block
                                v-for="aanmelding in aanmeldingenFilteredByStatus(statusGroups[activeStatusGroup].statussen)"
                                :key="aanmelding.id"
                                :aanmelding='aanmelding'
                                ></aanmeld-block>
                            </div>
                            <div class="col-md-8 offset-md-2 mt-3 text-center">
                              <p><small>
                                Opmerkingen of vragen over jouw aanmelding? Bel <a target='_blank' href="tel:+0305884464">030 5884464</a> of mail naar <a target='_blank' href="mailto:info@skipistenieuwegein.nl">info@skipistenieuwegein.nl</a>.
                                Wij streven ernaar om binnen 2 dagen contact met u op te nemen.
                              </small></p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 mt-5 mt-md-0 mb-5">
                        <div class="row">
                            <div class="col-12 mb-3 d-flex align-items-center justify-content-between">
                                <h4 class='mb-0' id="familie-title">Mijn familie</h4>
                                <router-link class="btn btn-secondary" :to="{ name: 'Account' }">Pas aan <font-awesome-icon class="ms-2" icon="fa-solid fa-arrow-right" /></router-link>
                            </div>
                            <div class="col-12">
                                <div id="familie-card">
                                    <div
                                    v-for="client in this.$store.state.familie.info.clients"
                                    :key="client.id"
                                    class="familie-card-item d-flex align-items-center justify-content-between"
                                    >
                                        <h5 class="mb-0">{{ client.firstname }}</h5>
                                        <h6 class="mb-0">{{ verjaardag(client.birthdate) }}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AanmeldBlock from '@/components/aanmeldingen/AanmeldBlock.vue'

export default {
  components: {
    AanmeldBlock
  },
  data () {
    return {
      loading: true,
      activeStatusGroup: 0,
      statusGroups: [
        {
          name: 'In behandeling',
          statussen: [1, 2, 3, 4]
        },
        {
          name: 'Ingepland',
          statussen: [5]
        },
        {
          name: 'Geannuleerd',
          statussen: [6]
        }

      ]
    }
  },
  methods: {
    verjaardag: function (date) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' }
      return new Date(date).toLocaleDateString('nl-NL', options)
    },
    aanmeldingenFilteredByStatus: function (statussen) {
      return this.aanmeldingen.filter(aanmelding => {
        return statussen.includes(aanmelding.aanmeldingAanmeldingStatusTypes[0].aanmeldingStatusTypeId)
      })
    },
    setActiveStatusGroup: function (index) {
      this.activeStatusGroup = index
    },
    checkRequiredFields: function () {
      const requiredFields = [
        'lastname',
        'adress',
        'zipcode',
        'city',
        'phone3'
      ]
      const requiredFieldsFilled = requiredFields.filter(field => {
        return this.$store.state.familie.info[field] && this.$store.state.familie.info[field] !== ''
      })
      return requiredFieldsFilled.length === requiredFields.length
    }
  },
  computed: {
    familieNaam: function () {
      return this.$store.state.familie.info.clients.length > 1 ? ', familie <strong>' + this.$store.state.familie.info.achternaamvol + '</strong>' : ''
    },
    aanmeldingStarten: function () {
      return this.$store.state.aanmelding.id === null
    },
    aanmeldingen: function () {
      return this.$store.state.familie.aanmeldingen
    },
    familieLeden () {
      return this.$store.state.familie.info.clients
    }
  },
  async mounted () {
    var self = this
    this.$store.dispatch('familie/getFamilie').then(function () {
      self.$store.dispatch('familie/getAanmeldingen').then(function () {
        if (!self.checkRequiredFields()) {
          self.$router.push({
            name: 'Account',
            params: {
              message: 'Vul eerst je gegevens in'
            }
          })
        }
        if (self.$store.state.familie.info.clients.length === 0) {
          self.$router.push({
            name: 'Account',
            params: {
              scrollDown: true,
              message: 'Je hebt nog geen gezinsleden toegevoegd. Voeg eerst een gezinslid toe om een aanmelding te kunnen starten.'
            }
          })
        }
        self.loading = false
      }
      )
    })
  }
}
</script>

<style scoped>
    body{
        background-color: #f5f5f5;
    }
    .btn-aanmelding{
        font-size: 1.2rem;
        padding: 1rem;
        transition: all .2s ease-in-out;
        border-radius: 17px !important;
        flex-grow: 1;
    }
    .btn-aanmelding:hover{
        transform: scale(1.04);
    }
    .btn-chip{
        background: rgba(0,0,0,.23);
        border-radius: 100px !important;
        font-weight: 500;
    }
    /* h4.options, #familie-title{
        color: rgba(0,0,0,.23);
        font-weight: bold;
        cursor: pointer;
        transition: all .2s ease-in-out;
    } */

    #option-holder{
      /* overflow x scroll  */
      overflow-x: scroll;
    }
    .options{
        font-weight: bold;
        cursor: pointer;
        background: rgba(0,0,0,.44) !important;
        color: #fff;
        padding: 10px 24px;
        border-radius: 100px;
        transition: all .2s ease-in-out;
        letter-spacing: 0px;
        white-space: nowrap;
    }
    #familie-title{
      cursor: default;
    }
    .options:hover{
      background:  var(--sp2) !important;
    }
    .active-option .options{
        background:  var(--sp1) !important;
    }
    #info-container{
        min-height: calc(100vh - 278px);
    }
    #aanmeldingen-grid{
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 0.9rem;
    }

    @media (max-width: 768px) {
        #aanmeldingen-grid{
            grid-template-columns: 1fr;
            grid-gap: 0.7rem;
        }
    }

    #verwijder-aanmelding{
        font-weight: 500;
        color: #ffffffaa;
        background: rgba(255,255,255,.23);
        border-radius: 17px !important;
        font-size: 1.2rem;
        padding: 1rem;
        width: 70px;
        margin-right: 8px;
    }

    .aanmelding-card{
        background: rgba(255,255,255,.23);
        color: #fff;
        opacity: 0.4;
        border-radius: 15px;
        padding: 20px;
        margin-top: 110px;
        margin-bottom: -120px;
        /* blur */
        -webkit-filter: blur(1px);
        -moz-filter: blur(1px);
        -o-filter: blur(1px);
        -ms-filter: blur(1px);
        filter: blur(1px);
        transform: scale(0.9);
        padding-bottom: 100px;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
    }
    #familie-card{
        background: #fff;
        border-radius: 10px;
        padding: 12px;
        /* box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px; */
        display: block;
        width: 100%;
    }
    .familie-card-item{
        width: 100%;
        border-bottom: 0.5px solid rgba(0,0,0,.1);
        padding: 12px;
        font-weight: bold;
    }
    .familie-card-item:last-child{
        border-bottom: none;
    }
</style>
