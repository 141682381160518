<template>
    <div class='aanmeld-rij  w-100 '>
        <div class='aanmeld-rij-head'>
            <h5 class='mb-0 mt-1'><strong>{{aanmelding.cursus.titel}}</strong></h5>
            {{aanmelding.customer.firstname}} &middot; {{aanmelding.skisnowboard.omschrijving}}<br>
        </div>
        <div>
        </div>
        <div>
            <small class="date">{{datum}}</small>
            <status-badge class='float-end' :status='aanmelding.aanmeldingAanmeldingStatusTypes[0]' :klant='klant'/><br>
        </div>

    </div>
</template>

<script>
import statusBadge from '@/components/aanmeldingen/StatusBadge.vue'
export default {
  name: 'AanmeldRij',
  components: {
    statusBadge
  },
  props: {
    aanmelding: {
      type: Object,
      required: true
    },
    klant: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  computed: {
    datum () {
      const date = new Date(this.aanmelding.signupdate)
      const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
      return date.toLocaleDateString('nl-NL', options)
    }
  }
}
</script>

<style lang="scss" scoped>
    .aanmeld-rij{
        padding: 20px;
        transition: all 50ms ease-in-out;
        border-radius: 10px;
        background: #fff;
        // border-bottom: 1px solid rgba(255,255,255,.84);
        // box-shadow: 0px 0px 10px rgba(0,0,0,0.14);
    }
    .aanmeld-rij:last-child{
        border-bottom: none;
        margin-bottom: 0;
    }

    .date{
        font-size: 12px;
        color: #999;
    }

</style>
